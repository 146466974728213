let baseUrl = '';
if (process.env.REACT_APP_API_BASE_URL) {
  baseUrl = process.env.REACT_APP_API_BASE_URL;
}
export const BASE_URL = baseUrl;

let env = '';
if (process.env.REACT_APP_ENV) {
  env = process.env.REACT_APP_ENV;
}

let apiKey = '';
if (process.env.REACT_APP_API_KEY) {
  apiKey = process.env.REACT_APP_API_KEY;
}

let tenantId = '';
if (process.env.REACT_APP_TENANT_ID) {
  tenantId = process.env.REACT_APP_TENANT_ID;
}

let authDomain = '';
if (process.env.REACT_APP_AUTH_DOMAIN) {
  authDomain = process.env.REACT_APP_AUTH_DOMAIN;
}


let authorUri = '';
if (process.env.REACT_APP_AUTHOR_URI) {
  authorUri = process.env.REACT_APP_AUTHOR_URI;
}

let decoratorUri = '';
if (process.env.REACT_APP_DECORATOR_URI) {
  decoratorUri = process.env.REACT_APP_DECORATOR_URI;
}

let fitroomUri = '';
if (process.env.REACT_APP_FITROOM_URI) {
  fitroomUri = process.env.REACT_APP_FITROOM_URI;
}

let texturaUri = '';
if (process.env.REACT_APP_TEXTURA_URI) {
  texturaUri = process.env.REACT_APP_TEXTURA_URI;
}

let seddiUri = '';
if (process.env.REACT_APP_SEDDI_URI) {
  seddiUri = process.env.REACT_APP_SEDDI_URI;
}

let mixpanelToken = '';
if (process.env.REACT_APP_AUTHOR_MIXPANEL) {
  mixpanelToken = process.env.REACT_APP_AUTHOR_MIXPANEL;
}

export const ENV = env;
export const API_KEY = apiKey;
export const TENANT_ID = tenantId;
export const AUTH_DOMAIN = authDomain;
export const TERMS_OF_SERVICE_UPDATED_AT = Date.UTC(2020, 8, 23); // September 23, 2020
export const AUTHOR_URI = authorUri;
export const DECORATOR_URI = decoratorUri;
export const FITROOM_URI = fitroomUri;
export const SEDDI_URI = seddiUri;
export const TEXTURA_URI = texturaUri;
export const GITLAB_UNLEASH_PROXY_BASE_URL = 'https://login-unleash-proxy-dguje7so2a-uc.a.run.app';
export const REACT_APP_AUTHOR_MIXPANEL = mixpanelToken;
