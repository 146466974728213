import React from 'react';
import { useParams } from 'react-router-dom';
import { I18n } from 'react-redux-i18n';

import { FormContainer } from '../../components/formContainer';
import { CommunicationPageTypes } from './CommunicationPage.types';
import { AUTHOR_URI, DECORATOR_URI, FITROOM_URI, SEDDI_URI, TEXTURA_URI } from '../../constants/constants';

import { StyledLink } from './CommunicationPage.styles';
import { useSelector } from 'react-redux';
import { SeddiLoginState } from '../../store/store';

export const CommunicationPage: React.FC = () => {
  const { name } = useSelector((state: SeddiLoginState) => state.app);
  const { type } = useParams();

  // function for return a url based on name
  const getBrandUrl = (name: string): string => {
    switch (name) {
      case 'fitroom':
        return FITROOM_URI;
      case 'textura':
        return TEXTURA_URI;
      case 'decorator':
        return DECORATOR_URI;
      case 'author':
        return AUTHOR_URI;
      case 'seddi':
      default:
        return SEDDI_URI;
    }
  }; 

  let [title, error, actionText, actionUrl]: (string | undefined)[] = [];

  switch (type) {
    case CommunicationPageTypes.SIGNUP: {
      title = I18n.t('error.wrong');
      error = I18n.t('error.singUpError');
      actionText = I18n.t('error.backSignup');
      actionUrl = '/create-account';
      break;
    }
    case CommunicationPageTypes.ACCOUNT_EXISTS: {
      title = I18n.t('error.wrong');
      error = I18n.t('error.accountExist');
      actionText = I18n.t('error.backSignup');
      actionUrl = '/create-account';
      break;
    }
    case CommunicationPageTypes.LOGIN: {
      title = I18n.t('error.wrong');
      error = I18n.t('error.logInError');
      actionText = I18n.t('error.backLogin');
      actionUrl = getBrandUrl(name);
      break;
    }
    case CommunicationPageTypes.AUTH: {
      title = I18n.t('error.wrong');
      error = I18n.t('error.auth');
      actionText = I18n.t('error.backLogin');
      actionUrl = getBrandUrl(name);
      break;
    }
    case CommunicationPageTypes.LOGOUT: {
      title = I18n.t('error.loggedOut');
      actionText = I18n.t('error.backLogout');
      actionUrl = getBrandUrl(name);
      break;
    }
    default:
      break;
  }

  return (
    <FormContainer title={title} text={error}>
      <StyledLink to={actionUrl}>{actionText}</StyledLink>
    </FormContainer>
  );
};
